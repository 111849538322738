<template>
    <div>
        <div class="table_wrap height">
            <el-table :data="tableData" @cell-click="cellClick" style="width: 100%" header-cell-class-name="table-header"
                cell-class-name="cell-class">
                <el-table-column v-for="(item, index) in tableOption" :key="index" :label="item.label"
                    :align="item.align || 'center'" :show-overflow-tooltip="item.overHidden || true">
                    <template slot-scope="scope">
                        <!-- <slot v-if="item.slot" :name="item.prop" :row="scope.row" :$index="scope.$index" /> -->
                        <span v-if="item.label == '充值额度' && scope.row.rechargeDetail">
                            <span v-for="(item2, i) in scope.row.rechargeDetail">
                                {{ item2.name }}-{{ item2.num }}/{{ item2.unit }};
                            </span>
                        </span>
                        <span v-else>{{ scope.row[item.prop] }}</span>
                    </template>
                    <!-- <template v-if="item.slot" v-slot:default="scope">
                        <slot :name="item.prop" :row="scope.row" :index="scope.$index"></slot>
                    </template>
                    <template v-else v-slot:default="scope">
                        <div>
                            {{ scope.row[item.prop] }}
                        </div>
                    </template> -->
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>
 
<script>
export default {
    name: 'ZtTable',
    components: {
    },
    props: {
        border: {
            type: Boolean,
            default: function () {
                return false
            }
        }, // 是否设置边框，默认不要
        operation: {
            type: Boolean,
            default: function () {
                return false
            }
        }, // 是否有操作列，默认无
        tableData: {
            type: Array,
            default: function () {
                return []
            }
        }, // 列表数据
        tableOption: {
            type: Array,
            default: function () {
                return []
            }
        }, // 表头
    },
    computed: {
    },
    methods: {
        cellClick(row, column, cell, event) {
            this.$emit('cell-click', { row, column, cell, event })
        }
    }
}
</script>

<style lang='scss' scoped>
@import "./index.scss";

</style>