<template>
    <div class="search-c">
        <!-- <div class="input-box">
            <el-input size="small" v-model="params.name" prefix-icon="el-icon-search" placeholder="请输入用户名称/ID"
                @change="search"></el-input>
        </div> -->
        <div class="leading-out flex_box">
            <el-radio-group v-model="params.timeType" size="small" @change="search">
                <el-radio-button :label="item.value" v-for="(item, index) in time_list" :key="index">{{ item.label
                }}</el-radio-button>
            </el-radio-group>
            <div class="time-box ml20">
                <el-date-picker :disabled="params.timeType != 4" style="width:100%" size='small' v-model="params.date" type="daterange"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                    @change="search" :clearable="false"></el-date-picker>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: {

    },
    data() {
        return {
            params: {
                timeType: 1,

                date: [],
            },
            time_list: [
                {
                    label: '今日',
                    value: 1
                },
                {
                    label: '本周',
                    value: 2
                },
                {
                    label: '本月',
                    value: 3
                },
                {
                    label: '自定义',
                    value: 4
                },
            ],
        }
    },
    methods: {
        search() {
            this.$emit('search', this.params)
        },
    }
}
</script>
<style lang="scss" scoped>
.search-c {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    height: 32px;
    .input-box {
        width: 160px;
    }

    .time-box {
        width: 312px;
    }

    .leading-out {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.flex_box {
    display: flex;
    align-items: center;
}

.ml20 {
    margin-left: 20px;
}

::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #2E4BF2;
    background-color: transparent;
    border-color: #2E4BF2;
    box-shadow: -1px 0 0 0 #2e4bf2;
}</style>